import React, { useMemo } from "react"
import { Layout } from "../../layouts"
import ZigZag from "../../components/ZigZag/ZigZag"
import "./Contact.scss"
import Contact from "../../components/Contact/Contact"
import contactPageTopImage from "../../assets/images/contact-page/contact-top-img.png"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../constants"
import { useZigzagImageSides } from "../../hooks/useZigzagImageSides"
import SEO from "../../components/Seo"
import { localizeDataNodes, localizeStaticNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

const ZIGZAG_IMAGE_SIDE_START = "left"

const ContactPage = ({ pageContext: { pageData, lang, companies }, data, location }) => {
  const { allDzemoApiZigzags } = useMemo(() => localizeDataNodes(data, lang), [
    data,
    lang,
  ])

  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )

  const localizedZigzags = useMemo(() => localizeStaticNodes(pageData[lang].zigzags, lang), [pageData, lang])

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="contact-page">
        <section className="top-img-wrapper">
          <img
            src={contactPageTopImage}
            alt="Contact page"
            width="1181"
            height="350"
          />
        </section>
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
          }}
        >
          <Contact
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
            withHeadingOne
          />
        </section>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
          {localizedZigzags.length > 0 &&
            localizedZigzags.map((zigzag, index) => {
              return (
                <ZigZag
                  key={zigzag.id}
                  data={{
                    ...zigzag,
                    image: zigzag.image[0],
                    sideOfImage: zigzagImageSides[index],
                  }}
                />
              )
            })}
        </section>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query contactPageQuery($pageId: Int!) {
    allDzemoApiZigzags(
      filter: { en: { page: { id: { eq: $pageId } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }
  }
`
